import React from "react";
import "../style.css";
import "../markdown-styles.module.css";
import axios from "axios";

import firebase from "firebase";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useHistory,
} from "react-router-dom";

export default class blog2 extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <>
        <h1>
          From Cloud Computing to Audio Engineering: Tao's Ventures in the World
          of Business and Technology
        </h1>
        <div style={{ marginTop: "12px" }}>
          Bian Lee • September 3, 2021 •{" "}
          <i>
            Interview with Tao Lin, a driven high school entrepreneur with quite
            an unconventional career
          </i>
        </div>

        <p>
          <br />
          This is the story of Tao Lin, a junior at Portola High School who
          founded{" "}
          <a href="https://www.mai.kiwi/" target="_blank">
            Maikiwi Global Network
          </a>
          , a private invitation-based Infrastructure as a Service (IaaS)
          company providing cloud services to small and established businesses.
          He is also an experienced audio engineer, with business experience in
          running an audio studio, handling music production from recording,
          mixing, and mastering. As a fellow high school student with interest
          in both technology and music (performance and production), I found
          Tao’s story inspiring, and I had the chance to interview him about his
          journey. I’ve compiled the interview into this blog format, with
          addition of my commentaries.
          <br />
          <br />
          <b>Bian Lee</b>: What was the motivation behind building Maikiwi
          Global Network?
          <br />
          <br />
          <b>Tao Lin</b>: It didn't really start off as a cloud service, like at
          all. My initial intent was to alleviate network congestion on one of
          the content distribution services that one of my friends ran.
          <br />
          <br />
          <b>Bian</b>: For readers who aren’t into tech, could you explain
          exactly what service you provide to businesses, in layman’s terms?
          <br />
          <br />
          <b>Tao</b>: Right, so we are at our core, an Infrastructure as a
          Service (IaaS) company. We provide cloud solutions and help enterprise
          clients to build their products, and we have the capacity and
          expertise to serve from small startups to established corporations. In
          layman's terms, we help companies build their backend service. A great
          example would be how Google Drive works, your documents and slides are
          stored on a cloud service that works to rename your files, move them
          to different folders, etc.
          <br />
          <br />
          <i>
            Essentially, Tao’s service makes it convenient for businesses to
            launch their product (or do whatever they need to do on the web) by
            taking care of everything on the backend, which are the technologies
            that work to keep web apps up, run databases, handle user requests,
            etc. It is a hybrid cloud deployment model, which combines many
            service models into a single deployment. (Of course, if you care to
            learn more about networking and cloud computing, you can always
            check out my note on the material, made accessible on the site).
          </i>
          <br />
          <br />
          <b>Bian</b>: So what role do you play in the business?
          <br />
          <br />
          <b>Tao</b>: We [Tao and his friend] would be the people behind
          providing those services to help to increase the hard drive space or
          the computational power needed to allow for more people to use the
          service simultaneously. To put it in basic terms, we increase the
          amount of compute power when there is a lot of traffic anticipated,
          and decrease them dynamically to save costs. I also help allocate
          traffic through different ISPs. For example, AT&amp;T from Los Angeles
          might have the best route compared to Telia when requesting data from
          Hong Kong. We don't code our services, instead we simply help build
          them on the cloud and scale as needed.
          <br />
          <br />
          <b>Bian</b>: What did it take to get actual clients who were willing
          to pay for this service?
          <br />
          <br />
          <b>Tao</b>: I mean, first of all, I already had one client at the very
          beginning who was extremely patient when working with me. Everyone
          that I work with currently trusts me as a reliable person, and so it's
          just friends spreading words to other friends at that point. The
          service has been invite-only up to around 3 weeks ago, but I've been
          thinking of opening up to the public, which is why the website and{" "}
          <a href="https://maikiwiglobalcdn.statuspage.io/" target="_blank">
            status page
          </a>{" "}
          went live very soon after.
          <br />
          <br />
          <b>Bian</b>: Do you face a lot of competitions / rival businesses?
          <br />
          <br />
          <b>Tao</b>: We are a very unique provider. We don't directly compete
          with any hyperscale IaaS companies like AWS or Azure since we operate
          on a hybrid model. What that basically means is that we cherry pick
          the best thing from each of our partners (Let's say networking
          endpoints from Azure, AI stuff from Google) and offer our clients
          basically 0 compromises for their product. We also operate our own
          infrastructure on our colocation center as well.
          <br />
          <br />
          <i>
            I was aware that Tao was also an audio engineer, and at this point,
            I decided to ask him questions about his other specialty in audio
            engineering and sound production.
          </i>
          <br />
          <br />
          <b>Bian</b>: Now onto the topic of audio engineering. What type of
          work do you exactly do and how did you get into that?
          <br />
          <br />
          <b>Tao</b>: So around late 2015 I got to meet one of the most skilled
          Mastering Engineers in China on the internet. We had a call and he
          thought that I have great taste for music and I should try out his
          job. So he taught me from scratch, and I learned by installing all the
          software and getting all the necessary hardware to start with, then I
          gained experiences by shadowing his work with him. I happen to know a
          few other music producers as well, so I tried to take their gigs for
          free. They started to like my work, and they became my first batch of
          clients.
          <br />
          <br />
          <b>Bian</b>: So you had a business relating to audio engineering as
          well?
          <br />
          <br />
          <b>Tao</b>: Yes, but I've scaled down the audio business a bit because
          I want to focus on cloud this year. But besides audio processing, we
          also tried to explore hardware. Something like a custom built audio
          recorder with all the amazing capabilities. I had a hard time managing
          the past summer break because of all the different meetings and
          management stuff from two separate businesses.
          <br />
          <br />
          <b>Bian</b>: How do you get all your businesses funded monetarily?
          <br />
          <br />
          <b>Tao</b>: At the very start, it was from my parents' investment and
          my own savings. Now if I want to do anything new, I can borrow money
          from both of my businesses.
          <br />
          <br />
          <i>
            At this point, I decided to wrap up the interview by asking what he
            wanted to pursue in the future.
          </i>
          <br />
          <br />
          <b>Bian</b>: You obviously have a quite unconventional journey as a
          high school student, and have experience in areas that wouldn't
          normally be even encountered in high school classroom settings. What
          made you decide to venture into this unique path and how would you
          advise other students to specialize in certain fields like you did?
          <br />
          <br />
          <b>Tao</b>: I think that as a high school student, just believe in
          what you do, really. I know a lot of great talented high schoolers
          just like me that just lack confidence. If you see something
          interesting, go try it. Learning something is always better than
          learning nothing and even if you won't ultimately use it often, at
          least you know that you don't like the subject. I'm a business-centric
          person after all. So if you can convince an investor/asset holder like
          me, and your parents, and your teacher, you are very probable onto a
          path of success.
          <br />
          <br />
          <b>Bian</b>: How do your experiences, early in life, help shape your
          future?
          <br />
          <br />
          <b>Tao</b>: After running two businesses since 2016 and 2018
          respectively, I know that I'm driven by passion. Wherever I go,
          whatever I do, I know that I am a person highly driven by my
          motivation. I believe in myself overcoming any challenges I see, and
          handling them accordingly.
          <br />
          <br />
          <b>Bian</b>: Lastly, do you plan on continuing to specialize in cloud
          computing and audio engineering? Or do you have another plan as to
          what you want to study and pursue in the future?
          <br />
          <br />
          <b>Tao</b>: To be honest, all these computer related things are just
          my hobbies that have accidentally turned into businesses. I think I
          want to study economics in the future. If it doesn't end well for me,
          I always have these other great experiences that I can use to feed
          myself.
          <br />
          <br />A heartening story. I was happy with how the interview went, and
          not only was I impressed by what he was able to accomplish, but I also
          found the whole story greatly inspiring. I thank Tao for allowing me
          conduct the interview and publish this blog, and I'm excited to see
          what he shall achieve in the future. For all the readers, I hope you
          found the post interesting as I plan to continue interviewing talented
          high schoolers and deliver inspiring stories on this platform.
        </p>
      </>
    );
  }
}
