import React from "react";
import "../style.css";
import "../markdown-styles.module.css";
import axios from "axios";

import firebase from "firebase";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useHistory,
} from "react-router-dom";

export default class blog6 extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <>
        <h1>
          TheCodingWizard: Nathan’s Rise to Becoming One of the Best Programmers
          and Problem Solvers of Our Generation
        </h1>
        <div style={{ marginTop: "12px" }}>
          Bian Lee • September 16, 2021 •{" "}
          <i>
            Interview with Nathan Wang, a 3x USACO Finalist, 5x AIME qualifier,
            and a full stack web developer who founded Competitive Programming
            Initiative and USACO guide
          </i>
        </div>

        <p>
          <br />
          This is the story of Nathan Wang, a high school senior from Cupertino,
          California. He is a 3x finalist at USA Computing Olympiad (USACO), 5x
          qualifier for the American Invitational Mathematical Examination
          (AIME), and a founder of{" "}
          <a href="https://joincpi.org/" target="_blank">
            Competitive Programing Initiative
          </a>{" "}
          (CPI) &{" "}
          <a href="https://usaco.guide" target="_blank">
            usaco.guide
          </a>{" "}
          (two prominent organization / website providing comprehensive guide to
          competitive programming). I had the chance to interview him this week
          about his accomplishments, interests, and how he got to becoming one
          of the top problem solvers today. Extremely skilled in both
          algorithmic programming as well as in web development, he is indeed
          “The Coding Wizard” (a name he calls himself) and undoubtedly the best
          programmer I personally know. Go check out his website:{" "}
          <a href="https://thecodingwizard.me/" target="_blank">
            https://thecodingwizard.me/
          </a>
          <br />
          <br />
          <b>Bian Lee</b>: How and when did you first get into programming and
          how did you develop your skills over time?
          <br />
          <br />
          <b>Nathan Wang</b>: I started programming from a very young age --
          around 8 years old. My dad taught me Lua, and I self-studied web
          development through some free Codecademy courses. I had a strong
          interest in programming and kept learning more about coding throughout
          my life, with a specialization in web development. I discovered
          competitive programming through my brother when I was in 7th grade;
          since then I've spent a considerable amount of time practicing
          competitive programming (USACO in particular) in addition to
          continuing to do web development on various projects I was interested
          in.
          <br />
          <br />
          <b>Bian</b>: I think it's really cool that you are talented at both
          competitive / algorithmic programming, as well as web development
          which require different sets of knowledge and skills. What do you like
          about each, and do you think being good at one helps you become better
          at the other?
          <br />
          <br />
          <b>Nathan</b>: For competitive programming, I like learning about cool
          algorithms / data structures and reading editorials to interesting
          problems. It's really fun thinking about those sorts of problems and
          trying to solve them. For web development, I like how versatile it is
          and how you can see what you create. You can do a lot of awesome
          things with web development, and the feedback from your code is
          immediate -- you make a change to your code and you can instantly see
          the UI change. This makes it highly enjoyable to create and refine
          websites. I don't necessarily know if being good at one would help you
          become better at the other. The skill sets seem quite distinct from
          each other. I guess both help develop general debugging and
          programming skills though.
          <br />
          <br />
          <b>Bian</b>: Interesting. Now onto the topic of USACO. Describe what
          it was like to work your way up in the competition. Was there any
          division that took you a long time to advance?
          <br />
          <br />
          <b>Nathan</b>: I did USACO much earlier, before the recent difficulty
          increases. I already had a strong programming background, and with a
          lot of luck I actually got promoted from Bronze to Gold in one
          contest, then Gold to Platinum in my second contest, without actually
          studying for USACO in specific. However, my actual skill level was
          really in the "basic Gold" range.
          <br />
          <br />
          <b>Bian</b>: Once you made it to Platinum, what did it take to become
          a finalist?
          <br />
          <br />
          <b>Nathan</b>: I took Gold and Platinum summer camp at Alphastar, read
          through some of Competitive Programming 3, and spent a year doing a
          bunch of old USACO problems (I did almost all Gold problems and ~75%
          of Platinum problems). This, combined with a lot of luck, helped me
          barely qualify to be a finalist in 2019.
          <br />
          <br />
          <b>Bian</b>: I know you are also a 5 time qualifier for AIME. When and
          how did you get into competition math, and what did it take to qualify
          for AIME numerous times? Also, do you find any similarities between
          USACO and AIME questions and do you ever apply the same problem
          solving techniques?
          <br />
          <br />
          <b>Nathan</b>: I started competitive math at a young age, and was
          introduced to it by my parents. I had math tutoring from a young age;
          this helped me get good enough to qualify for AIME. I didn't notice
          any similarities between USACO and AIME, though I heard from others
          that coming from a competitive math background helps with competitive
          programming (Although it's probably not worth doing competitive math
          in order to improve at competitive programming -- it's more time
          efficient to just practice competitive programming directly).
          <br />
          <br />
          <b>Bian</b>: You are a founder of usaco.guide as well as Competitive
          Programming Initiative. What was your motivation behind building the
          two?
          <br />
          <br />
          <b>Nathan</b>: For USACO.guide, I wanted to update the training pages
          for USACO, but the idea grew from there and we ended up building an
          entire learning platform that's now the USACO Guide. As for
          Competitive Programming Initiative, we wanted to expand competitive
          programming by increasing awareness to more students, and we also
          wanted to create a more equal playing field for USACO, where talent
          and effort was worth more than financial resources
          <br />
          <br />
          <b>Bian</b>: On the development of usaco.guide website -- Did you
          start building the full stack app from scratch by yourself? How is the
          codebase managed now?
          <br />
          <br />
          <b>Nathan</b>: I built the app from scratch (though I used a lot of
          libraries and frameworks to help me out). The codebase has two parts,
          the website and the actual content. The website hasn't changed much
          recently. The actual content is being worked on by a large team of
          content authors and content managers. Everything is managed by the
          parent organization, the Competitive Programming Initiative.
          <br />
          <br />
          <b>Bian</b>: How did you grow the two over time? What did it take to
          grow the user base?
          <br />
          <br />
          <b>Nathan</b>: It grew quite organically; we didn't have to do much
          advertisement. The USACO Guide got a lot of initial traffic from a CF
          [CodeForces] blog post, and then it was posted on the USACO.org
          training page, so its growth and traffic is largely self-sustaining
          now. Competitive Programming Initiative was harder to grow, but with
          the USACO Guide, Google searches, and various other outreach
          initiatives we gained decent traffic.
          <br />
          <br />
          <b>Bian</b>: Besides math & computing, what are some of your other
          hobbies / interests?
          <br />
          <br />
          <b>Nathan</b>: I like business (FBLA), policy (Model UN), speech &
          debate as well! I also really enjoy playing board games, and
          especially social deduction games like The Resistance.
          <br />
          <br />
          <b>Bian</b>: What do you hope to pursue in the future, and what advice
          can you give to other younger students?
          <br />
          <br />
          <b>Nathan</b>: I'm not really sure what I'm looking to do in the
          future, but I'm sure it will have something to do with code! I don't
          really have any good advice for younger students unfortunately, but
          maybe starting USACO as early as possible? I guess?
          <br />
          <br />
          <b>Bian</b>: What music do you listen to?
          <br />
          <br />
          <b>Nathan</b>: Twice! Which has anecdotally been proven to improve
          competitive programming contest performance.
          <br />
          <br />
          Nathan is definitely off-the-charts brilliant when it comes to
          computing, as well as running his own initiative. Among so many
          student organizations I’ve seen, none has made as much impact as
          Competitive Programming Initiative and its numerous projects,
          including usaco.guide. It is extremely inspiring to see how a single
          driven individual can accomplish so much, from numerous successes in
          olympiads, and writing a whole codebase oneself to establish one of
          the biggest and widely used websites among students. It seems
          TheCodingWizard’s power through coding is truly unstoppable, not to
          mention his leadership ability that has also proven extremely
          effective in carrying the initiatives this far. I am personally deeply
          inspired by Nathan’s story and am excited to see what great things he
          should accomplish in the future. I want to thank Nathan once again for
          allowing me to conduct this interview and share his story.
        </p>
      </>
    );
  }
}
